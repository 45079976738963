.map-box {
    flex: 1 1 auto;
    height: 100%;
    overflow: hidden;
    background-color: #e0e0e0;
}

.menu-box-vertical-ruler {
    height: 100%;
    flex: 0 0 2px;
    background-color: #e0e0e0;
}

.menu-box {
    flex: 0 0 200px;
    flex-direction: column;
    margin: 1ex;
}

.flying-token-layer {
    position:absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.main-layer {
    display: flex;
    position:absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.legend-black {
    stroke: Black;
    stroke-width: 5px;
}

.legend-white {
    stroke: #e0e0e0;
    stroke-width: 5px;
}

@keyframes laser-pointer-fading {
    0% { opacity: 1 }
    10% { opacity: 1 }
    100% { opacity: 0 }
}

.laser-pointer {
    pointer-events: none;
    transform: translate(0, 0);
}

.laser-pointer-fading {
    pointer-events: none;
    animation-name: laser-pointer-fading;
    animation-duration: 100s;
    transform: translate(0, 0);
}
