.AdminLogin {
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

label.AdminLogin {
  margin: 20px;
}

button.AdminLogin {
  margin: 20px;
}

.Admin {
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.item-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.box-item {
    flex: 0 0 600px;
    vertical-align: middle;
    margin: 4px;
}

.logout {
    position: fixed;
    top: 10px;
    right: 10px;
}